import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { ApiResponse, FeedbackProvider } from '../interfaces';
import { axiosBaseQuery } from './base.service';
import { buildEndpointWithQuery } from '@vision/ui/utils';

export const FeedbackProviderService = createApi({
  reducerPath: 'FeedbackProviderService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getFeedbackProviders: builder.query<ApiResponse<FeedbackProvider[]>, { nodeId: string; query?: string }>({
      query: ({ nodeId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/feedback_providers`, query),
        method: HttpMethod.GET,
      }),
    }),
    getFeedbackProvider: builder.query<ApiResponse<FeedbackProvider>, { nodeId: string; id: string }>({
      query: ({ nodeId, id }) => ({
        url: `/v2/accounts/${nodeId}/feedback_providers/${id}`,
        method: HttpMethod.GET,
      }),
    }),
  }),
});

export const { useGetFeedbackProvidersQuery, useGetFeedbackProviderQuery } = FeedbackProviderService;
