import { createSlice } from '@reduxjs/toolkit';
import { ContentCategory } from '@vision/ui/interfaces';
import { ContentCategoriesService } from '@vision/ui/services';

export interface ContentCategoriesStateType {
  contentCategories: ContentCategory[];
}

const initialState: ContentCategoriesStateType = {
  contentCategories: [],
};

export const ContentCategoriesState = createSlice({
  name: 'ContentCategoriesState',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(ContentCategoriesService.endpoints.getContentCategories.matchFulfilled, (state, { payload }) => {
      state.contentCategories = payload;
    });
  },
});
