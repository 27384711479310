import { useDebouncedCallback } from '@mantine/hooks';
import {
  ReportChartDataRequestItem,
  ReportChartGroupItemType,
  ReportChartJobIdResponse,
  ReportFeedbackResponseArrayDataType,
  ReportFeedbackResponseCollection,
  ReportFeedbackStaticResponseItem,
} from '@vision/ui/interfaces';
import { useCreateFeedbackOrJobIdMutation, useGetFeedbackStaticResultQuery } from '@vision/ui/services';
import { useEffect, useState } from 'react';
import { selectContentCategoriesSelector } from '../store/states/content-categories-state/content-categories-state.selector';
import { ENV, reportWidgetHasContentCategoryCompare } from '../utils';
import { useAppSelector } from './useAppSelector';

function isReportChartJobIdResponse(obj: any): obj is ReportChartJobIdResponse {
  return isObjectWithProperty(obj, 'job_id');
}

function isReportFeedbackResponseCollection(obj: any): obj is ReportFeedbackResponseCollection {
  return isObjectWithProperty(obj, 'data');
}

function isObjectWithProperty(obj: any, property: string): boolean {
  return obj && typeof obj[property] !== 'undefined';
}

const updateCategoryContentNames = (data: any, contentCategories: any[]) => {
  const typedData = data as ReportFeedbackResponseArrayDataType[];
  return typedData.map((item) => {
    const contentCategory = contentCategories.find((contentCategory) => contentCategory.path === item.name);
    return { ...item, name: contentCategory?.name };
  });
};

export interface UseReportFeedbackStaticProps {
  widgetId?: string;
  widgetType?: ReportChartGroupItemType;
}

const reportingAsyncDisabled = ENV.REPORTING_ASYNC_DISABLED;

export function useReportFeedbackStatic({ widgetId, widgetType }: UseReportFeedbackStaticProps) {
  const contentCategories = useAppSelector(selectContentCategoriesSelector);

  const passiveAsync = (() => {
    const flag = localStorage.getItem('FLAG_GET_REPORT_PASSIVE_ASYNC');
    return flag === 'true' || (flag === null && reportingAsyncDisabled);
  })();

  // All report data
  const [allData, setAllData] = useState<Array<ReportFeedbackStaticResponseItem>>([]);
  // job id getting function that will be used for all operations in this hook
  // but if async is not sent here, data is returned directly.
  const [createJobId] = useCreateFeedbackOrJobIdMutation();
  // job id in process
  const [activeRequestJobId, setActiveRequestJobId] = useState<string | null>(null);
  // When Start Request and End Request
  const [isRequesting, setIsRequesting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  // received during operations
  const [processItems, setProcessItems] = useState<Array<ReportFeedbackStaticResponseItem>>([]);

  const {
    data: responseChartStaticData,
    isFetching: isFetchingChartStaticData,
    isError: isErrorChartStaticData,
    originalArgs,
  } = useGetFeedbackStaticResultQuery(
    {
      jobId: activeRequestJobId,
    },
    {
      skip: !activeRequestJobId,
      pollingInterval: 1000,
      refetchOnFocus: false,
      skipPollingIfUnfocused: true,
      refetchOnMountOrArgChange: false,
    },
  );

  const handleOnResetData = () => {
    setAllData([]);
    setIsError(false);
    setActiveRequestJobId(null);
    setIsTimedOut(false);
  };

  useEffect(() => {
    if (isErrorChartStaticData) {
      setIsRequesting(false);
      setActiveRequestJobId(null);
    }
  }, [isErrorChartStaticData]);

  const handleOnRequest = (values: ReportChartDataRequestItem[], async: boolean = true) => {
    handleOnResetData();
    setIsRequesting(true);
    // If widgetId is provided, async defaults to true. If not, async must be false.
    requestGraphicDataJobId(values, passiveAsync ? false : widgetId ? async : false);
  };

  const handleNextJobId = (items: Array<ReportFeedbackStaticResponseItem>) => {
    const otherItems = items.filter((item) => !item.data && item.jobId);
    if (otherItems.length > 0) {
      const nextItem = items.find((item) => !item.data && item.jobId);
      setActiveRequestJobId(nextItem.jobId);
    } else {
      setAllData(items);
      setActiveRequestJobId(null);
      setIsRequesting(false);
    }
  };

  useEffect(() => {
    if (responseChartStaticData && !isFetchingChartStaticData) {
      if (responseChartStaticData.status === 'completed') {
        // If the originalArgs?.jobId in the incoming response is equal to the incoming jobId, complete the operation.
        const items = processItems.map((item) => {
          if (item.jobId === originalArgs?.jobId) {
            if (reportWidgetHasContentCategoryCompare(widgetType, item.compare)) {
              return {
                ...item,
                data: updateCategoryContentNames(responseChartStaticData.result.data, contentCategories),
              };
            }
            return { ...item, data: responseChartStaticData.result.data };
          }
          return item;
        });

        setProcessItems(items);
        handleNextJobId(items);
      } else if (responseChartStaticData.status === 'failed') {
        setIsRequesting(false);
        setActiveRequestJobId(null);
      } else if (responseChartStaticData.status === 'timed_out') {
        setIsRequesting(false);
        setActiveRequestJobId(null);
        setIsTimedOut(true);
      }
    }
  }, [responseChartStaticData, isFetchingChartStaticData]);

  const requestGraphicDataJobId = useDebouncedCallback(
    async (chartRequests: ReportChartDataRequestItem[], async: boolean) => {
      try {
        // Eğer passiveAsync true ise async false olarak gönderilir.
        // First get the job id to start all the processes
        const responses = await Promise.all(
          chartRequests.map(({ url, data, measure, compare }) =>
            createJobId({ params: data, url, widgetId, async })
              .unwrap()
              .then((response) => {
                return { measure, data: response, compare };
              })
              .catch((error) => {
                console.error('error', error);
                throw error;
              }),
          ),
        );
        // @TodoRepoting: If have a error in the responses, handle it.
        const items: ReportFeedbackStaticResponseItem[] = [];

        // get the job id of the processes after they start
        for (const response of responses) {
          if (isReportChartJobIdResponse(response.data)) {
            const jobId = response.data.job_id;
            items.push({ measure: response.measure, jobId, data: null, compare: response.compare });
          } else if (isReportFeedbackResponseCollection(response.data)) {
            // Dont trust backend check response.data.data
            // if direct return data add data but jobId null
            // because requests will be made continuously for the values received that have a job id and no data.

            if (reportWidgetHasContentCategoryCompare(widgetType, response.compare)) {
              items.push({
                measure: response.measure,
                jobId: null,
                data: updateCategoryContentNames(response.data.data, contentCategories),
                compare: response.compare,
              });
            } else {
              items.push({
                measure: response.measure,
                jobId: null,
                data: response.data.data,
                compare: response.compare,
              });
            }
          }
        }
        setProcessItems(items);

        handleNextJobId(items);
      } catch (error) {
        console.error('error', error);
        setIsRequesting(false);
        setActiveRequestJobId(null);
        setIsError(true);
      }
    },
    1000,
  );

  return {
    allData,
    handleOnResetData,
    handleOnRequest,
    isRequesting,
    isError: isErrorChartStaticData || isError,
    isTimedOut,
  };
}
