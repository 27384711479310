import { ComboboxItemGroup } from '@mantine/core';
import { TreemapSeriesNodeItemOption } from '@vision/ui/components';
import { ReportTimeIntervalType } from '@vision/ui/enums';
import i18n from '@vision/ui/i18n';
import {
  INode,
  ReportChartCompareTransform,
  ReportChartGroupCompare,
  ReportChartGroupCompareItemType,
  ReportChartGroupItemMeasure,
  ReportChartMeasureType,
  ReportChartTheme,
  ReportChartThemeDisplayFormat,
  ReportChartThemeLabelDisplayFormat,
  ReportChartThemeSerieItem,
  ReportFeedbackNameType,
  ReportFeedbackResponse,
  ReportFeedbackResponseDataArray,
  TreeSelectItemType,
} from '@vision/ui/interfaces';
import {
  convertArrayToGroupSelectItems,
  convertArrayToSelectItems,
  ensureArray,
  formatDate,
  formatNumber,
  getValueByActiveLanguage,
  truncateString,
} from '@vision/ui/utils';
import dayjs from 'dayjs';
import { CallbackDataParams, TopLevelFormatterParams } from 'echarts/types/dist/shared';
import { Dictionary } from 'echarts/types/src/util/types';

export function getReportDataName(name: ReportFeedbackNameType): string {
  if (typeof name === 'string' || typeof name === 'number') {
    return name.toString();
  }

  return getValueByActiveLanguage(name) || Object.values(name)[0] || '';
}

export function reportChartIsValidValue(value: ReportFeedbackNameType): boolean {
  return value !== null && value !== undefined && value !== '';
}

export const createChannelSelectItems = (channelTypes: string[], nodes: INode[]): ComboboxItemGroup[] => {
  return channelTypes.map((channelType) => {
    const channels = nodes.filter((item) => item.type === channelType);
    return {
      group: i18n.t(`channels.${channelType}`),
      items: convertArrayToSelectItems(channels, (item) => ({
        label: item.name,
        value: item.id,
      })),
    } as ComboboxItemGroup;
  });
};

//  burada compare değişince hangi değerlerin silineceğini belirliyoruz
export function getReportChartCompareKey(keyType: ReportChartGroupCompareItemType[]): string[] {
  return ReportChartCompareTransform.filter((item) => keyType.includes(item.keyType)).flatMap(
    (item) => item.compareKey,
  );
}

function getLegendStatusByLabel(label: string, seriesItems: ReportChartThemeSerieItem[]): boolean {
  const item = seriesItems.find((color) => color.label === label);

  if (item?.hidden) {
    return false;
  }

  return true;
}

export function getLegendStatus(labels: string[], seriesItems: ReportChartThemeSerieItem[]): Dictionary<boolean> {
  if (!seriesItems.length) {
    return {};
  }

  return labels.reduce((acc, label) => {
    acc[label] = getLegendStatusByLabel(label, seriesItems);
    return acc;
  }, {} as Dictionary<boolean>);
}

export function retrieveLegendStatus(labels: string[], seriesItems: ReportChartThemeSerieItem[]): Dictionary<boolean> {
  if (!seriesItems.length) {
    return {};
  }

  const legendStatus: Dictionary<boolean> = {};

  labels.forEach((label) => {
    legendStatus[label] = getLegendStatusByLabel(label, seriesItems);
  });

  return legendStatus;
}

export function isReportFeedbackResponseDataArray(data: ReportFeedbackResponse[]): boolean {
  return ensureArray(data).some((item) => Array.isArray(item.data));
}

export function isReportFeedbackResponseDataNumber(data: ReportFeedbackResponse[]): boolean {
  return ensureArray(data).some((item) => typeof item.data === 'number');
}

// isReportFeedbackResponseDataArray fonk buna göre düzelt
export function isReportFeedbackResponseDataArrayItem(
  data: ReportFeedbackResponse['data'],
): data is ReportFeedbackResponseDataArray {
  return Array.isArray(data);
}

export function secondsToHHMMSS(value: number): string {
  const hours = Math.floor(value / 3600);
  //  (Math/floor (/ x 60))
  const minutes = Math.floor((value - hours * 3600) / 60);
  // (Math/floor (- value (* (:data hours) 3600) (* (:data minutes) 60)))
  const seconds = Math.floor(value - hours * 3600 - minutes * 60);

  const hhMmSs = [
    { data: hours, trans: i18n.t('hours') },
    { data: minutes, trans: i18n.t('minutes') },
    { data: seconds, trans: i18n.t('seconds') },
  ].filter((val) => val.data > 0);

  return hhMmSs.map((val) => `${val.data} ${val.trans}`).join(' ');
}

export function reportChartDisplayDateFromInterval(value: number, interval: ReportTimeIntervalType): string {
  if (interval === ReportTimeIntervalType.MONTH) {
    return formatDate(value, 'monthYear', i18n.language).date;
  } else if (interval === ReportTimeIntervalType.QUARTER) {
    return formatDate(value, 'quarter', i18n.language).date;
  }

  return formatDate(value, 'full', i18n.language).date;
}

export function reportChartLabelFormatter(
  compare: ReportChartGroupCompareItemType,
  label: string,
  truncate: number = 20,
): string {
  try {
    let result = label.toString();
    if (!result) {
      return '';
    }

    switch (compare) {
      // If the comparison is by day, we return the day of the week.
      case 'time-period-by-day':
        result = dayjs.localeData().weekdays()[Number(result)];
        break;
      case 'time-period-by-month':
        // data is like January 1, February 2, March 3
        result = dayjs()
          .month(Number(result) - 1)
          .format('MMMM');
        break;
      case 'user-archiving':
      case 'user-assigned':
      case 'user-responsible':
      case 'user-best-assigned':
      case 'user-worst-assigned':
        result = result === ' ' ? i18n.t('unknownUser') : result;
        break;
      case 'question-title':
        result = getReportDataName(result);
        break;
    }

    if (truncate < 1) {
      return result;
    }
    if (!result) {
      return label;
    }
    return truncateString(result, truncate, '...');
  } catch (e) {
    console.error('reportChartLabelFormatter error', e, 'compare', compare, 'label', label);
    return label as string;
  }
}

export function reportChartDefaultDataLabelFormatter(
  measure: ReportChartMeasureType,
  label: string,
  maximumFractionDigits: number = 2,
): string {
  switch (measure) {
    case 'feedback-csat':
    case 'feedback-nps':
    case 'nps-kpi':
    case 'csat-kpi':
    case 'feedback-weighted-score':
    case 'feedback-sentiment-score':
    case 'nps-realization-rate':
    case 'csat-realization-rate':
      return formatNumber(Number(label), {
        style: 'decimal',
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: maximumFractionDigits,
      });
    case 'action-time-archived':
    case 'action-time-completion':
    case 'action-time-internal-note':
    case 'action-time-read':
    case 'action-time-response':
      return secondsToHHMMSS(Number(label));
    default:
      return label;
  }
}

export function reportChartSortLabels(labels: string[], compare: ReportChartGroupCompareItemType): string[] {
  switch (compare) {
    case 'time-period-by-day':
    case 'time-period-by-month':
    case 'time-period-by-hour':
      return labels.sort((a, b) => Number(a) - Number(b));
    default:
      return labels.sort();
  }
}

export function defaultReportChartNumberFormatter(value: number): string {
  return formatNumber(value, { style: 'decimal', maximumFractionDigits: 2 });
}

export function convertToSelectGroupItems(
  items: Array<ReportChartGroupItemMeasure | ReportChartGroupCompare>,
  groupTranslateKey: string,
  groupItemTranslateKey: string,
) {
  return convertArrayToGroupSelectItems(items, (item) => ({
    group: i18n.t(`page-reports:${groupTranslateKey}.${item.type}`),
    items: item.items.map((childItem) => ({
      label: i18n.t(`page-reports:${groupItemTranslateKey}.${childItem}`),
      value: childItem,
    })),
  }));
}

export function convertTreeSelectItemTypeToTreeSeriesData(data: TreeSelectItemType[]): TreemapSeriesNodeItemOption[] {
  return data.map((item) => {
    return {
      name: item.label,
      value: item.value,
      children: convertTreeSelectItemTypeToTreeSeriesData(item.children),
      data: item.data,
    };
  });
}

export function getReportChartCompareAxisValue(
  compare: ReportChartGroupCompareItemType[],
  axis: 'x' | 'y' = 'x',
): ReportChartGroupCompareItemType {
  if (compare.length < 2) {
    return compare[0];
  }

  let valueX = compare[0];
  let valueY = compare[1];

  if (
    (compare.includes('time-period-by-hour') && compare.includes('time-period-by-day')) ||
    (compare.includes('time-period-by-hour') && compare.includes('time-period-by-month')) ||
    (compare.includes('time-period-by-day') && compare.includes('time-period-by-month'))
  ) {
    valueX = compare[0];
    valueY = compare[1];
  } else if (compare.includes('time-period-by-hour')) {
    valueX = 'time-period-by-hour';
    valueY = compare.filter((item) => item !== 'time-period-by-hour')[0];
  } else if (compare.includes('time-period-by-month')) {
    valueX = 'time-period-by-month';
    valueY = compare.filter((item) => item !== 'time-period-by-month')[0];
  } else if (compare.includes('time-period-by-day')) {
    valueX = 'time-period-by-day';
    valueY = compare.filter((item) => item !== 'time-period-by-day')[0];
  } else if (compare.includes('unit-name')) {
    valueX = 'unit-name';
    valueY = compare.filter((item) => item !== 'unit-name')[0];
  }

  if (axis === 'x') {
    return valueX;
  }

  return valueY;
}

export function reportChartFormatSecondsToHMS(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  // Saniyenin küsuratını iki basamakla sınırlama
  const formattedSecs = secs.toFixed(2).replace(/\.00$/, ''); // Tam sayı ise küsuratı kaldır

  // Saat ve dakika sıfırsa sadece saniyeyi döndür
  if (hours === 0 && minutes === 0) {
    return `${i18n.t('secondsShort', { value: +formattedSecs })}`;
  }

  // Sadece saat sıfırsa, dakikayı ve saniyeyi döndür
  if (hours === 0) {
    return `${i18n.t('minutesShort', { value: +minutes })} ${i18n.t('secondsShort', { value: +formattedSecs })}`;
  }

  // Saat ve dakika sıfır değilse, hepsini döndür
  return `${i18n.t('hoursShort', { value: +hours })} ${i18n.t('minutesShort', { value: +minutes })} ${i18n.t('secondsShort', { value: +formattedSecs })}`;
}

export function formatReportChartDataDisplay(
  value: number,
  measure: ReportChartMeasureType,
  displayFormat: ReportChartThemeDisplayFormat,
): string {
  if (displayFormat === 'auto') {
    let digit = 2;
    switch (measure) {
      case 'feedback-count':
      case 'response-count':
      case 'response-ranking-overview':
      case 'customer-count':
      case 'matrix-question-csat-overview':
      case 'matrix-question-response-count':
        digit = 0;
        break;
      case 'feedback-csat':
      case 'feedback-nps':
      case 'nps-kpi':
      case 'csat-kpi':
      case 'feedback-weighted-score':
      case 'feedback-sentiment-score':
        digit = 2;
        break;
      case 'action-time-archived':
      case 'action-time-completion':
      case 'action-time-internal-note':
      case 'action-time-read':
      case 'action-time-response':
        return reportChartFormatSecondsToHMS(value);
      default:
        digit = 2;
        break;
    }

    return formatNumber(value, { style: 'decimal', maximumFractionDigits: digit, minimumFractionDigits: digit });
  }

  switch (measure) {
    case 'feedback-count':
      return formatNumber(value, { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 });
    case 'action-time-archived':
    case 'action-time-completion':
    case 'action-time-internal-note':
    case 'action-time-read':
    case 'action-time-response':
      return reportChartFormatSecondsToHMS(value);
    default:
      return formatNumber(value, {
        style: 'decimal',
        maximumFractionDigits: displayFormat as number,
        minimumFractionDigits: displayFormat as number,
      });
  }
}

export function formatReportChartAxisSimpleValue(value: number, displayFormat: ReportChartThemeDisplayFormat): string {
  if (displayFormat === 'auto') {
    return formatNumber(value, { style: 'decimal', maximumFractionDigits: 2 });
  }

  return formatNumber(value, {
    style: 'decimal',
    maximumFractionDigits: displayFormat as number,
    minimumFractionDigits: displayFormat as number,
  });
}

export function formatReportChartAxisPercentValue(
  value: number,
  data: number[],
  displayFormat: ReportChartThemeDisplayFormat,
): string {
  const total = data.reduce((acc, item) => acc + item, 0);

  // total sıfırsa, percentage'ı 0 olarak ayarlıyoruz
  const percentage = total === 0 ? 0 : (value / total) * 100;

  return (
    formatNumber(percentage, {
      style: 'decimal',
      maximumFractionDigits: displayFormat === 'auto' ? 2 : (displayFormat as number),
      minimumFractionDigits: displayFormat === 'auto' ? 2 : (displayFormat as number),
    }) + '%'
  );
}

export function formatBarChartAxisValue(
  value: number,
  displayFormat: ReportChartThemeDisplayFormat,
  labelDisplayFormat: ReportChartThemeLabelDisplayFormat,
  selectedMeasure: ReportChartMeasureType,
  data: number[],
): string {
  if (labelDisplayFormat === 'percentage') {
    return formatReportChartAxisPercentValue(value, data, displayFormat);
  } else if (labelDisplayFormat === 'bothOfThem') {
    return `${formatReportChartDataDisplay(value, selectedMeasure, displayFormat)} (${formatReportChartAxisPercentValue(value, data, displayFormat)})`;
  }

  return formatReportChartDataDisplay(value, selectedMeasure, displayFormat);
}

// If want to other charts, change name and use. because this is only for bar chart
export function formatMultipleOrSingleBarChartTooltip(
  seriesData: { name: string; data: number[] }[],
  params: TopLevelFormatterParams,
  compare: ReportChartGroupCompareItemType[],
  selectedTheme: ReportChartTheme,
  selectedMeasure: ReportChartMeasureType,
) {
  const tooltipData = params as (CallbackDataParams & { axisValue: number | string })[];
  const { axisValue, data, marker } = tooltipData[0];

  const isMultipleCompare = compare.length > 1;

  if (isMultipleCompare) {
    const items = tooltipData
      .filter((item) => item.data !== null)
      .map((item) => {
        const itemLabel = reportChartLabelFormatter(getReportChartCompareAxisValue(compare, 'x'), item.seriesName, -1);

        const itemDataFormatted = formatBarChartAxisValue(
          item.data as number,
          selectedTheme?.dataLabels?.displayFormat,
          selectedTheme?.dataLabels?.labelDisplayFormat,
          selectedMeasure,
          seriesData.find((item) => item.name === itemLabel)?.data as number[],
        );
        return `${item.marker} ${itemLabel} ${itemDataFormatted}`;
      })
      .join('<br />');

    return `${reportChartLabelFormatter(getReportChartCompareAxisValue(compare, 'y'), axisValue.toString(), 0)} <br/>${items}`;
  } else {
    const itemDataFormatted = formatBarChartAxisValue(
      data as number,
      selectedTheme?.dataLabels?.displayFormat,
      selectedTheme?.dataLabels?.labelDisplayFormat,
      selectedMeasure,
      seriesData[0].data as number[],
    );

    return `${marker} ${reportChartLabelFormatter(getReportChartCompareAxisValue(compare), axisValue.toString(), 0)} ${itemDataFormatted}`;
  }
}
